<template>
    <div>
      <!-- error handelr -->
      <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
        variant="danger">
        <h4 class="alert-heading">
          Alert
        </h4>
        <div class="alert-body">
          <ul v-for="values in errors_back" :key="values">
            <li v-for="value in values" :key="value">{{ value }}</li>
          </ul>
        </div>
      </b-alert>
      <b-card title="Packages">
        <b-row>
          <!-- sorting -->
          <b-col md="4" class="my-1">
            <b-form-group label="Sort By" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect"
              class="mb-0">
  
              <b-input-group size="sm">
                <b-form-select id="sortBySelect" @change="sortingTable" v-model="sortBy" :options="sortOptions"
                  class="w-75">
                </b-form-select>
                <b-form-select @change="sortingTable" v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
         
          <b-col cols="1" class="my-1">
            <!-- print and save pdf & excel -->
            <div class="ml-auto mr-2">
              <b-dropdown style="height: 2.142rem; line-height: 0.5;" v-ripple.400="'rgba(113, 102, 240, 0.15)'" right
                variant="primary" size="sm" class="dropdown-icon-wrapper">
                <template #button-content><feather-icon icon="PrinterIcon" size="16" class="align-middle" />
                </template>
                <download-excel :data="exportItems">
                  <b-dropdown-item>
                    <feather-icon icon="DownloadIcon" /> Download
                    Excel</b-dropdown-item>
                </download-excel>
                <b-dropdown-divider />
                <b-dropdown-item @click="genPDF">
                  <feather-icon icon="DownloadIcon" /> Save PDF</b-dropdown-item>
                <b-dropdown-divider />
              </b-dropdown>
            </div>
          </b-col>
          <!-- start data table -->
          <b-col cols="12">
            <b-table id="tagTable" ref="admint" striped hover responsive show-empty :busy.sync="isBusy"
              class="position-relative" :per-page="perPage" @sort-changed="fetchData" :items="items" :fields="fields"
              :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
              @row-clicked="packagesQuickView">
  
              <template #cell(name)="data">
                <!-- <p>  ar:  {{ JSON.parse( data.item.name).name_ar}}</p> -->
                <p> {{ JSON.parse(data.item.name).name_en }} </p>
              </template>
  
  
              <template #cell(description)="data">
                <!-- <p>  ar:   <span v-html="JSON.parse( data.item.description).description_ar.substring(0, 8) + '..'"> </span></p> -->
                <p> <span v-html="JSON.parse(data.item.description).description_en.substring(0, 15) + '..'"> </span> </p>
              </template>
  
  
  
              <template #cell(filter_data)="data">
                <span v-if="data.item.filter_data != null">
  
                  <p v-if="JSON.parse(data.item.description).description_ar != null">
                    <span class="text-success">Start Date:</span> {{ JSON.parse(data.item.filter_data).description_ar }}
                  </p>
                  <p v-if="JSON.parse(data.item.description).description_en != null">
                    <span class="text-success">End Date:</span> {{ JSON.parse(data.item.description).description_en }}
                  </p>
                  <p v-if="JSON.parse(data.item.filter_data).gender != null">
                    <span class="text-success">Gender:</span> {{ JSON.parse(data.item.filter_data).gender }}
                  </p>
                  <p v-if="JSON.parse(data.item.filter_data).UserCategory != null">
                    <span class="text-success">User Category:</span> {{ JSON.parse(data.item.filter_data).UserCategory }}
                  </p>
                </span>
              </template>
  
              <template #cell(created_at)="data">
                {{ data.item.created_at | formatDate }}
              </template>
  
              <template #cell(deleted_at)="data">
                {{ data.item.deleted_at | formatDate }}
              </template>
  
  
              <template #cell(actions)="items">
               
  
        
                        <div class="demo-inline-spacing">
                        <b-button
                        style="padding: 7px; margin: 6px; width: 35px; height: 35px;"
                        v-if="authPermissions.includes('update')"
                        @click="restorePac(items.item.id)"
                        variant="warning"
                        class="btn-icon rounded-circle"
                        >
                        <feather-icon
                            icon="RefreshCwIcon"
                            style="width: 18px; height: 18px;"
                        />
                        </b-button>
                    </div>

              </template>
  
            </b-table>
  
  
          
  
  
            <b-modal v-model="modalShow" id="packages-quick-view-modal" hide-footer ref="modal" centered
              title="packages Details" size="lg">
  
              <b-row v-if="packagesModal">
                <b-col md="12">
                  <p class="text-primary h4 mt-1">
                    Code: <span class="h5">{{ packagesModal.id }}</span>
                  </p>
  
                  <p class="text-primary h4 mt-1" v-if="JSON.parse(packagesModal.name).name_ar != null">
                    Name ar: <span class="text-dark ml-2">{{ JSON.parse(packagesModal.name).name_ar }}</span>
                  </p>
                  <p class="text-primary h4 mt-1" v-if="JSON.parse(packagesModal.name).name_en != null">
                    Name en: <span class="text-dark ml-2">{{ JSON.parse(packagesModal.name).name_en }}</span>
                  </p>
  
  
                  <p class="text-primary h4 mt-1" v-if="JSON.parse(packagesModal.description).description_ar != null">
                    description Ar: <b-badge variant="light" class="p-1">
                      <span class="text-primary" style=" white-space: pre-wrap !important;"
                        v-html="JSON.parse(packagesModal.description).description_ar"> </span>
                    </b-badge>
                  </p>
  
                  <p class="text-primary h4 mt-1" v-if="JSON.parse(packagesModal.description).description_en != null">
                    description En: <b-badge variant="light" class="p-1">
                      <span class="text-primary" style=" white-space: pre-wrap !important;"
                        v-html="JSON.parse(packagesModal.description).description_en"> </span>
                    </b-badge>
                  </p>
  
                  <p class="text-primary h4 mt-1">
                    price:
                    <b-badge variant="info">{{ packagesModal.price }}</b-badge>
                  </p>
  
                  <p class="text-primary h4 mt-1">
                    Number of ads:
                    <b-badge variant="info">{{ packagesModal.ads_limit }}</b-badge>
                  </p>
  
                  
                    <b-col md="12" class="mt-4">
                      <p class="text-secondary h4 mt-1">Payments</p>
                      <table class="table table-striped ">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Fees</th>
                            <th scope="col">Active</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(payment,index) in packagesModal.payments" :key="index">
                            <td dir="rtl">{{ payment.name }}</td>
                            <td style="text-align: center">{{ payment.payment_provider.fees }}</td>
                            <td>
                              <b-badge>
                                {{ payment.payment_provider.is_active }}
                              </b-badge>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-col>
  
  
                  
                  <b-col md="12" class="mt-4">
                      <p class="text-secondary h4 mt-1">category</p>
                      <table class="table table-striped ">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Code</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                          <tr   v-for="(category_translation,index) in packagesModal.category.category_translations" :key="index">
                            <td v-if="category_translation.locale == 'en'" dir="rtl">{{ category_translation.name }}</td>
                            <td  v-if="category_translation.locale == 'en'" style="text-align: center">{{ packagesModal.category.code }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </b-col>
  
  
  
  
  
                  <p class="text-primary h4 mt-1">
                    Created At:
                    <b-badge variant="info">{{ packagesModal.created_at | formatDate }}</b-badge>
                  </p>
                  <p class="text-primary h4 mt-1">
                    Deleted At:
                    <b-badge variant="info">{{ packagesModal.deleted_at | formatDate }}</b-badge>
                  </p>
  
                </b-col>
              </b-row>
            </b-modal>
          </b-col>
        </b-row>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select id="perPageSelect" @change="fetchData" v-model="perPage" size="sm" :options="pageOptions"
              class="w-50" />
          </b-form-group>
          <!-- pagination -->
          <div>
            <b-pagination @input="fetchData()" aria-controls="CategoryTable" v-model="currentPage" :total-rows="rows"
              :per-page="perPage" align="center" class="my-0" />
          </div>
        </b-card-body>
      </b-card>
    </div>
  </template>
      
  <script>
  import { VueEditor } from 'vue2-editor'
  import { required, integer } from '@validations'
  import loggerVue from '../logger.vue'
  
  export default {
    components: {
      VueEditor,
    },
    data() {
      return {
        authPermissions: null,
        deactive: '0',
        areaID: null,
        deletedArea: '0',
        showDismissibleAlert: false,
        errors_back: [],
        errors_delete: {},
        perPage: 25,
        pageOptions: [25, 50, 100],
        totalRows: 1,
        isBusy: false,
        currentPage: 1,
        sortBy: 'created_at',
        sortDesc: true,
        sortDirection: 'desc',
        filter: '',
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        categoryOption: [
          {
            value: 'select_value',
            text: 'Select Value',
          },
        ],
        payments: [
          {
            value: 'select_value',
            text: 'Select Value',
          },
        ],
        required,
        packagesModal: '',
        TypeFilter: '',
        TypeModelFilter: '',
  
        AllBody: '',
        modalShow: false,
  
        fields: [
          {
            key: 'id',
            label: 'Id',
            sortable: true,
          },
          {
            key: 'name',
            label: 'Name',
          },
          {
            key: 'description',
            label: 'Description',
          },
          {
            key: 'ads_limit',
            label: 'Number of ads',
          },
          {
            key: 'days_until_expiry',
            label: 'Days Until Expiry',
          },
          {
            key: 'price',
            label: 'Price',
          },
  
          {
            key: 'created_at',
            label: 'Created_At',
            sortable: true,
          },
          {
            key: 'deleted_at',
            label: 'Deleted_At',
            sortable: true,
          },
  
          {
            key: 'actions',
            label: 'actions',
          },
  
  
  
        ],
        items: [],
        exportItems: [],
  
        updatedPackage: {
          name_ar: '',
          name_en: '',
          description_ar: '',
          description_en: '',
          price: null,
          ads_limit: null,
          is_active: true,
        },
        paymet_methods: [],
      }
    },
    created() {
      this.authPermissions = this.$store.state.authPermissions
      // request tags index
      this.fetchData()
  
  
      axios
        .get('categories')
        .then((result) => {
          const data = result.data.data
          for (let cat in data) {
            if (data[cat].translation != null) {
              this.categoryOption.push({
                value: data[cat].id.toString(),
                text: data[cat].translation.name
              })
            } else {
              this.categoryOption.push({
                value: data[cat].id.toString(),
                text: 'undefinded',
              })
            }
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        });
  
  
  
      axios
        .get('getPaymentProvidersSession')
        .then((result) => {
          const data = result.data.data;
  
          for (let cat in data) {
            this.payments.push({
              value: data[cat].id.toString(),
              text: data[cat].name
            });
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        });
  
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => ({
            text: f.label,
            value: f.key,
          }))
      },
      rows() {
        return this.totalRows
      },
    },
    watch: {
      // sortDirection: function () {
      //   this.sortingTable()
      // },
      // sortBy: function () {
      //   this.sortingTable()
      // },
      filter: function () {
        if (this.filter == '' || this.filter == null) {
          this.fetchData()
        }
      },
    },
    methods: {
      // fetch data of areas
      fetchData() {
        var url = null
        if (this.TypeFilter == '') {
          url =
            'packages/trashed/' +
            this.perPage.toString() +
            '/sortBy-' +
            this.sortBy +
            '-' +
            this.sortDirection +
            '?page=' +
            this.currentPage
        } else {
          url =
            'packages//trashed/' +
            this.perPage.toString() +
            '/sortBy-' +
            this.sortBy +
            '-' +
            this.sortDirection +
            '/' +
            this.TypeFilter +
            '?page=' +
            this.currentPage
        }
        const promise = axios.get(url, { "model_type": this.TypeModelFilter })
        return promise
          .then((result) => {
            this.totalRows = result.data.data.total;
            this.items.length = 0;
            this.items = result.data.data;
            this.exportItems = this.items;
            this.$refs.admint.refresh();
            return this.items
          })
          .catch((err) => {
            this.errors_back.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back = []
              this.errors_back.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
      },
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      // sort function
      sortingTable() {
        this.currentPage = 1
        if (this.sortDesc == true) {
          this.sortDirection = 'desc'
        } else {
          this.sortDirection = 'asc'
        }
        var url = null
        if (this.filter == '') {
          url =
            'packages/trashed/' +
            this.perPage.toString() +
            '/sortBy-' +
            this.sortBy +
            '-' +
            this.sortDirection +
            '?page=' +
            this.currentPage
        } else {
          url =
            'packages/trashed/' +
            this.perPage.toString() +
            '/sortBy-' +
            this.sortBy +
            '-' +
            this.sortDirection +
            '/' +
            this.TypeFilter +
            '?page=' +
            this.currentPage
        }
        const promise = axios.get(url, { "model_type": this.TypeModelFilter })
        return promise
          .then((result) => {
  
            this.totalRows = result.data.data.meta.total
            this.items.length = 0
            this.items = result.data.data.data
            this.exportItems = this.items
            this.$refs.admint.refresh()
            return this.items
          })
          .catch((err) => {
            this.errors_back.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back = []
              this.errors_back.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
      },
  
      // delete function to delete area
      deleteData(id) {
        this.$bvModal
          .msgBoxConfirm(
            'Please confirm that you want to delete post category.',
            {
              title: 'Are You Sure?',
              size: 'sm',
              okVariant: 'danger',
              okTitle: 'Yes',
              cancelTitle: 'No',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then((value) => {
            if (value == true) {
              axios
                .delete('post-categories/' + id)
                .then((result) => {
                  this.$swal({
                    position: 'center',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  this.fetchData()
                })
                .catch((err) => {
                  this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: 'Error!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  this.errors_back.length = 0
                  if (err.response.data.data != null) {
                    if (this.isString(err.response.data.data)) {
                      this.errors_back.push({
                        error: err.response.data.data,
                      })
                    } else {
                      this.errors_back = err.response.data.data
                    }
                    this.showDismissibleAlert = true
                  } else {
                    this.errors_back = []
                    this.errors_back.push({
                      error: 'internal server error',
                    })
                    this.showDismissibleAlert = true
                  }
                })
            }
          })
      },
  
  
  
      deletePackage(id) {
        this.$bvModal
          .msgBoxConfirm("Please confirm that you want to delete This Package.", {
            title: "Are You Sure?",
            size: "sm",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              axios
                .delete('packages/' + id)
                .then((result) => {
                  this.$swal({
                    position: "center",
                    icon: "success",
                    title: "Your work has been saved",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.fetchData();
                })
                .catch((err) => {
                  this.$swal({
                    position: "center",
                    icon: "error",
                    title: "Error!",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.errors_back.length = 0;
                  if (err.response.data.data != null) {
                    if (this.isString(err.response.data.data)) {
                      this.errors_back.push({
                        error: err.response.data.data,
                      });
                    } else {
                      this.errors_back = err.response.data.data;
                    }
                    this.showDismissibleAlert = true;
                  } else {
                    this.errors_back = [];
                    this.errors_back.push({
                      error: "internal server error",
                    });
                    this.showDismissibleAlert = true;
                  }
                });
            }
          });
      },
  
  
      packagesQuickView(item, index, event) {
        this.$bvModal.show('packages-quick-view-modal')
        this.packagesModal = item
      },
  
  
      addpaymetmethods() {
        this.paymet_methods.push({});
      },
      subpaymetmethods(index,paymentId,packageId) {
        this.$bvModal
          .msgBoxConfirm("Please confirm that you want to delete This payment.", {
            title: "Are You Sure?",
            size: "sm",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              axios
                .delete('packages-payment/'+ paymentId +'/' + packageId )
                .then((result) => {
                  this.$swal({
                    position: "center",
                    icon: "success",
                    title: "Your work has been saved",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.paymet_methods.splice(index, 1);
                  this.fetchData();
                })
                .catch((err) => {
                  this.$swal({
                    position: "center",
                    icon: "error",
                    title: "Error!",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.errors_back.length = 0;
                  if (err.response.data.data != null) {
                    if (this.isString(err.response.data.data)) {
                      this.errors_back.push({
                        error: err.response.data.data,
                      });
                    } else {
                      this.errors_back = err.response.data.data;
                    }
                    this.showDismissibleAlert = true;
                  } else {
                    this.errors_back = [];
                    this.errors_back.push({
                      error: "internal server error",
                    });
                    this.showDismissibleAlert = true;
                  }
                });
            }
          });
  
      },


// restore function
    restorePac(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to restore This Package.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            const url = this.$session.get('lang')
            axios
              .get('packages/' + id + '/restore')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
  
  
  
      genPDF() {
        const { jsPDF } = require("jspdf");
        const doc = new jsPDF()
        let print_body = []
        for (let index = 0; index < this.items.length; index++) {
          print_body[index] = [
            this.items[index].id,
            this.items[index].name,
            this.items[index].description,
            this.items[index].price,
            this.items[index].num_ads,
            this.items[index].created_at,
            this.items[index].updated_at,
          ]
        }
        doc.autoTable({
          head: [['ID', 'Name', 'Description', 'price', 'num_ads', 'CREATED_AT', 'UPDATED_AT']],
          body: print_body,
        })
        doc.save('table.pdf')
      },
      isString(value) {
        return typeof value === 'string' || value instanceof String
      },
    },
  }
  </script>
    
    
    
  <style>
  .modal-backdrop {
    opacity: 0.1;
    background-color: #22292f;
    position: fixed;
    top: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
  }
  
  .packagess-table {
    max-height: 70vh !important;
  }
  
  .m-50 {
    margin-left: 50px;
  }
  
  .badge {
    white-space: pre-wrap !important;
  }
  </style>
      